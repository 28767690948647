.nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.navbar {
  width: 740px;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;

  /* flex: 100%; */
}

.nav-button {
  font-size: 2vh;
  text-align: center;
  display: inline-block;
  margin: 5px;
  font-weight: bold;
  padding: 10px 10px 10px 10px;
  background-color: #fdd612;
  color: rgb(0, 0, 0);
  border-radius: 7px;
  box-shadow: 0 0.2em #d4b101; 
  cursor: pointer;
  text-decoration: none;
  width: 150px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.nav-button:hover {
  box-shadow: none;
  transform: translateY(0.2em); /* Moves the button down by 0.2em */
}


/* .nav-button {
  font-size: 2vh;
  text-align: center;
  display: inline-block;
  margin: 5px;
  font-weight: bold;
  padding: 10px 10px 10px 10px; */
  /* background-color: rgb(255, 255, 255); */
  /* background-color: #fdd612;
  color: rgb(0, 0, 0);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px; */
  /* box-shadow: 0 0.2em rgb(202, 201, 201); */
  /* box-shadow: 0 0.2em #d4b101; 
  cursor: pointer;
  text-decoration: none;
  width: 150px;
} */

/* .nav-button:hover {
  cursor: pointer;
  box-shadow: none;
  position: relative;
  top: 0.3em;
} */

.nav-button:disabled {
  color: rgb(255, 255, 255);
  position: none;
  top: 0em;
  box-shadow: none;
  background-color: rgb(175, 171, 171);
}
/* @media screen and (max-width: 900px) {
  .navbar {
    width: 100%;
    
  }
  .nav-button {
    width: 100%;
  }
} */

@media screen and (max-width: 740px) {
  .navbar {
    width: 700px;
    /* padding-left: 32px;
    padding-right: 32px; */
  }
  .nav-button {
    /* width: 100%; */
    /* width: 130px; */
    font-size: 2.0vh;
  }
}
@media screen and (max-width: 660px) {
  .navbar {
    width: 580px;
    /* padding-left: 32px;
    padding-right: 32px; */
  }
  .nav-button {
    /* width: 100%; */
    /* width: 130px; */
    font-size: 1.9vh;
  }
}
@media screen and (max-width: 590px) {
  .navbar {
    width: 570px;
    /* padding-left: 32px;
    padding-right: 32px; */
  }
  .nav-button {
    /* width: 100%; */
    /* width: 130px; */
    font-size: 1.9vh;
  }
}
@media screen and (max-width: 575px) {
  .navbar {
    width: 550px;
    /* padding-left: 32px;
    padding-right: 32px; */
  }
  .nav-button {
    /* width: 100%; */
    /* width: 130px; */
    font-size: 1.9vh;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    width: 100%;

    /* padding-left: 12px;
    padding-right: 12px; */
  }
  .nav-button {
    width: 100%;
    /* flex: 30%; */
    font-size: 1.8vh;
  }
}

@media screen and (max-width: 345px) {
  .navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
     padding-left: 12px; 
    padding-right: 12px; 
  }
  .nav-button {
    width: 100%;
    /* flex: 30%; */
    font-size: 1.8vh;
  }
}
