.from-to-container {
  display: flex;
  flex-direction: column;
}

.from-to-container > h2  {
  font-size: 3vh;
  color: #fdd612;
}

.edu-exp-container {
  display: flex;
  justify-content: center;
}

.edu-container {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.edu-item {
  margin: 20px;
  max-width: auto;
  background-color: rgb(27, 25, 25);
  border-radius: 10px;
  border: 2px solid white;
  color: transparent;
}

.edu-item:hover {
  background-color: black;
  }

.exp-item {
	margin: 20px;
	max-width: auto;
	background-color: rgb(27, 25, 25);
	border-radius: 10px;
	border: 2px solid white;
}

.exp-item:hover{
  background-color: black;
}

.edu-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.edu-desc {
  text-align: left;
  float: left;
  margin-left: 5px;
  margin-bottom: 10px;
}
.edu-desc > * {
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.edu-desc > p {
  font-size: 1.5vh;
  font-weight: 200;
  color: white;
}
.edu-desc > p > strong {
  /* color: black; */
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bolder;
  font-size: 1.8vh;
  font-weight: 700;
  /* color: white; */
  color: #fdd612;
}

.edu-desc > span {
  font-style: italic;
  font-size: 1.4vh;
  color: white;
}

.edu-time {
  text-align: right;
  float: right !important;
  margin-right: 5px;
  width: 100%;
}

.edu-time > span {
    color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-style: italic;
  font-size: 1vh;
}

.line {
  margin-top: 5%;
  border-left: 3px solid white;
  border-radius: 10px;
}

@media screen and (max-width: 760px) {
  .from-to-container > h2  {
    font-size: 2.8vh;
  }
  .edu-time {
    float: right;
    margin-right: 5px;
    width: 100%;
  }
  .edu-desc > p > strong {
	color: white;
    font-weight: 700;
    font-size: 1.6vh;
  }

  .edu-desc > p {
	color: white;
    font-size: 1.3vh;
  }

  .edu-desc > span {
	color: white;
    font-style: italic;
    font-size: 1.1vh;
  }

  .edu-time > span {
	color: white;
    font-style: italic;
    font-size: 0.8vh;
  }
  .line {
    margin-top: 8%;
  }
  .edu-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .from-to-container > h2  {
    font-size: 2.5vh;
  }
  .edu-desc > p > strong {
	color: white;
    font-weight: 700;
    font-size: 1.3vh;
  }

  .edu-desc > p {
	color: white;
    font-size: 0.9vh;
  }

  .edu-desc > span {
    color: white;
    font-style: italic;
    font-size: 0.7vh;
  }

  .edu-time {
    text-align: right;
    margin-right: 5px;
  }

  .edu-time > span {
	color: white;
    font-style: italic;
    font-size: 0.5vh;
  }
  .line {
    margin-top: 12%;
  }
  .edu-container {
    width: 100%;
  }
}
