.resume-container {
  width: 600px;
  text-align: center;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.my-resume-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 250px; */
  gap: 30px;
  /* width: 100%; */
}
.my-resume-container > p {
  font-size: 15px;
}

.page-head > h2 {
  font-size: 3vh;
  display: inline-block;
  background-color: black;
  color: #fdd612;
}

.email-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.email-input > span {
  align-self: center;
  font-size: 13px;
}
#email-input {
  width: 300px;
  color: black;
  height: 50px;
  font-size: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.my-resume {
  width: 100%;
}

.download-button-container {
  width: 80%;
}
.request{
  background-color: white !important;
  box-shadow: 0 0.2em rgb(202, 201, 201); 
}

@media screen and (max-width: 760px) {
  .resume-container {
    width: 100%;
  }
  .my-resume {
    min-width: 100%;
  }
  .page-head > h2 {
    font-size: 2.8vh;
  }
}

@media screen and (max-width: 480px) {
  .resume-container {
    width: 100%;
  }
  .my-resume {
    min-width: 100%;
  }
  .page-head > h2 {
    font-size: 2.5vh;
  }

  #email-input {
    width: 100%;
  }
}
