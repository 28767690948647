/* *, */
/* *::before,
*::after {
  box-sizing: border-box;
} */

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.rainbow {
  position: relative;
  z-index: 0;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size:
      90% 50%,
      50% 50%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;

    animation:
      rotate 2s infinite,
      changeColor 8s infinite;
    -webkit-animation:
      rotate 2s infinite,
      changeColor 8s infinite;
    -moz-animation:
      rotate 2s infinite,
      changeColor 8s infinite;
    -ms-animation:
      rotate 2s infinite,
      changeColor 8s infinite;
    -o-animation:
      rotate 2s infinite,
      changeColor 8s infinite;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Glow effect */
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border-radius: 50%;
    /* animation: changeBorderColor 8s infinite;
    -webkit-animation: changeBorderColor 8s infinite;
    -moz-animation: changeBorderColor 8s infinite;
    -ms-animation: changeBorderColor 8s infinite;
    -o-animation: changeBorderColor 8s infinite; */
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); Glow effect */
  }
}
@keyframes changeColor {
  0% {
    background-color: #ffffff;
    background-image: linear-gradient(#f2f2f2, #ffffff, #ffffff);
    box-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
  }

  15% {
    background-color: #f2f2f2;
    background-image: linear-gradient(#e5e5e5, #f2f2f2, #f2f2f2);
    box-shadow: 0 0 10px #f2f2f2, 0 0 20px #f2f2f2, 0 0 30px #f2f2f2;
  }

  30% {
    background-color: #e5e5e5;
    background-image: linear-gradient(#d8d8d8, #e5e5e5, #e5e5e5);
    box-shadow: 0 0 10px #e5e5e5, 0 0 20px #e5e5e5, 0 0 30px #e5e5e5;
  }

  45% {
    background-color: #d8d8d8;
    background-image: linear-gradient(#cccccc, #d8d8d8, #d8d8d8);
    box-shadow: 0 0 10px #d8d8d8, 0 0 20px #d8d8d8, 0 0 30px #d8d8d8;
  }

  60% {
    background-color: #cccccc;
    background-image: linear-gradient(#bfbfbf, #cccccc, #cccccc);
    box-shadow: 0 0 10px #cccccc, 0 0 20px #cccccc, 0 0 30px #cccccc;
  }

  70% {
    background-color: #bfbfbf;
    background-image: linear-gradient(#b3b3b3, #bfbfbf, #bfbfbf);
    box-shadow: 0 0 10px #bfbfbf, 0 0 20px #bfbfbf, 0 0 30px #bfbfbf;
  }

  85% {
    background-color: #b3b3b3;
    background-image: linear-gradient(#a6a6a6, #b3b3b3, #b3b3b3);
    box-shadow: 0 0 10px #b3b3b3, 0 0 20px #b3b3b3, 0 0 30px #b3b3b3;
  }

  100% {
    background-color: #a6a6a6;
    background-image: linear-gradient(#999999, #a6a6a6, #a6a6a6);
    box-shadow: 0 0 10px #a6a6a6, 0 0 20px #a6a6a6, 0 0 30px #a6a6a6;
  }
}



.roat {
  margin-top: 3px;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  max-width: 100%;
  object-position: center;
}

@media screen and (max-width: 600px) {
  .roat {
    /* height: 110px;
    width: 110px; */
  }

  .rainbow {
    /* height: 115px;
    width: 115px; */
  }
}

@media screen and (max-width: 480px) {
  .roat {
    height: 117px;
    width: 117px;
  }
  .rainbow {
    height: 123px;
    width: 123px;
  }
}
