.blogs-container {
  width: 550px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-head > h1 {
  display: inline-block;
  background-color: black;
  font-size: 3vh;
  color: #fdd612 !important;
}

.blogs-container > p {
  text-decoration: underline;
  cursor: pointer;
  font-size: 2vh;
  color: white;
  display: inline-block;
}
.blogs-container > p:hover {
  color: #39ff14;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* height: fit-content; */
}
.filter-button-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 10px; */
  background-color: rgb(27, 25, 25);
  height: 40px;
  border-radius: 10px;
}
.filter-button>p>small{
	font-size: 16px;
	width: 37px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border: 2px solid white;
	/* width: 10px; */
}
.filter-button:hover > p > small {
  /* border: 1px solid white; */
}

.filter-button:hover {
	/* border: 2px solid white; */
  }
.reset {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(27, 25, 25);
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
}

.reset:hover > svg {
  transform: rotate(-360deg);
}
.reset > svg {
  transition: transform 1s ease;
}



.filter-button > p {
  /* text-decoration: underline; */
}

.arrow {
  display: inline-block;
  padding: 3px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  cursor: pointer;
}

.arrow:hover {
  cursor: pointer;
  box-shadow: none;
  position: relative;
  top: 0.2em;
}

.filter-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  /* border: 2px solid transparent; */
}

.category {
  /* background-color: rgb(27, 25, 25) !important; */
  border: 1px solid transparent !important;
  width: fit-content;
  text-align: center;
  border-radius: 3px;
  /* color: white ; */
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer !important;
}

.category:hover {
  border: 1px solid white !important;
}

@media screen and (max-width: 760px) {
  .blogs-container {
    /* width: 100%; */
    /* padding-left: 32px;
		padding-right: 32px; */
    /* margin: 16px; */
  }

  .blogs-container > p {
    font-size: 1.8vh;
  }
  .page-head > h1  {
    font-size: 2.8vh;
  }
}

@media screen and (max-width: 560px) {
  .blogs-container {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    /* margin: 16px; */
  }
  .blogs-container > p {
    font-size: 1.6vh;
  }
  .page-head > h1{
    font-size: 2.5vh;
  }
}
