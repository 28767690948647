.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: black; */
}

.contact-container > * {
  margin: 15px;
  cursor: pointer;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
   width: 45px; 
  height: 45px; 
}

/* svg{
  max-width: 100%;
} */

@keyframes kreep {
  0% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }
  50% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5rem);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.kreep {
 
  animation: none;
}

.kreep:hover {
  animation: kreep 0.7s ease 0s infinite alternate;
}

/* svg{
 width: 100%;
 height: 100%;

} */

@media screen and (max-width: 760px) {
  .contact-container > * {
    margin: 12px;
  }
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .contact-container > * {
    margin: 10px;
  }
}
