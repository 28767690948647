.about-item-container {
  display: flex;
  flex-direction: column;
  width: 700px;
}
.about-item {
  display: flex;
  flex-direction: column;
}

.about-item > h3 > span {
  text-align: center;
  font-style: bold;
  font-size: 3vh;
  color: #fdd612;
}

.about-item > p {
  display: flex;
  text-align: center;
  font-size: 2vh;
  line-height: 1.5;
  margin: 10px;
  /* color: #39ff14; */
  color: #fdd612;
}
.preview-button-container {
  display: flex;
  justify-content: center;
}
.preview-button {
  font-size: 1.7vh;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  color: rgb(0, 0, 0);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.preview-button:hover {
  cursor: pointer;
  box-shadow: none;
  position: relative;
  top: 0.3em;
}

.preview-button > span {
  color: black;
}

.preview-reverse-container {
  display: flex;
  justify-content: center;
  /* width: 100%;
	overflow: hidden; */
}

.preview-image-container {
  display: flex;
  justify-content: center;
  height: 450px;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
}

.preview-img {
  border: 2px solid white;
}

.preview-image {
  animation: preview-image-container 1s;
  margin-top: 0%;
  height: auto;
  width: 100%;
  object-fit: scale-down;
}

@keyframes preview-image-container {
  from {
    margin-top: -100%;
  }

  to {
    margin-top: 0%;
  }
}

.next-image {
  display: flex;
  flex-direction: row;
}

.next-image > div > button {
  color: black;
  font-size: 1.8vh;
}

.lt {
  position: relative;
  top: 50%;
  cursor: pointer;
}

.gt {
  position: relative;
  top: 50%;
  cursor: pointer;
}

/* Paste the CSS for blockquote here */
blockquote {
  font-style: italic;
  color: #ffffff; /* Adjust the color to your preference */
  border-left: 4px solid #ccc; /* Left border to highlight the quote */
  margin: 20px 0; /* Spacing around the blockquote */
  padding: 10px 20px; /* Padding inside the blockquote */ /* Background color for better readability */
  quotes: "“" "”" "‘" "’"; /* Using typographic quotes */
  /* line-height: 1;  */
}

blockquote:before {
  content: open-quote;
  font-size: 2em; /* Adjust the size of the opening quote */
  color: #ccc; /* Color of the opening quote */
  /* vertical-align: -0.4em; Adjust vertical alignment */
  margin-right: 0.25em; /* Space between quote and text */
}

blockquote:after {
  content: close-quote;
  font-size: 2em; /* Adjust the size of the closing quote */
  color: #ccc; /* Color of the closing quote */
  /* vertical-align: -0.4em; Adjust vertical alignment */
  margin-left: 0.25em; /* Space between text and quote */
}

blockquote p {
  display: inline; /* Display paragraphs inline within the blockquote */
}

@media screen and (max-width: 760px) {
  .about-item-container {
    width: 100%;
  }
  .about-item {
    text-align: center;
  }
  .about-item > h3 {
    line-height: 1.8;
    font-size: 2.8vh;
  }
  .about-item > p {
    font-size: 1.8vh;
  }

  .next-image > div > button {
    color: black;
    font-size: 1.5vh;
  }
  blockquote {
    margin: 15px 0;
    padding: 8px 12px;
  }

  blockquote:before,
  blockquote:after {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  .about-item {
    text-align: center;
  }
  .about-item-container {
    width: 100%;
  }
  .about-item > h3 {
    font-size: 2.5vh;
    line-height: 1.2;
  }
  .about-item > p {
    font-size: 1.6vh;
  }

  .next-image > div > button {
    color: black;
    font-size: 1.2vh;
  }
}
