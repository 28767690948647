.intro-container {
  width: 450px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 15px; */
  /* margin-bottom:10px; */
}

.avatar {
  /* height: 130px;*/
  width: 450px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  background-size: auto;
  /* margin-bottom: 10%; */
}
/* *, *::before, *::after {
	box-sizing:border-box;
  } */

.avatar-back {
  height: 130px;
  width: 450px;
}

.avatar-image {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -70px;
}

.contents {
  /* margin-top: 25%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.contents > * {
  background-color: black;
}

.contents > p {
  font-size: 1.8vh;
  filter: contrast(200%);
  color: white;
  font-family: Futura;
}

.contents > p > span {
  font-size: 2.3vh;
  font-style: italic;
  filter: contrast(200%);
  color: white;
  font-family: Futura;
}

.contents > p > strong {
  font-family: Futura;
  font-size: 3.5vh;
  line-height: 2;
  color: #fdd612;
}

.contents > .location {
  font-size: 2vh;
}
.contents > span > i {
  font-size: 1.8vh;
  font-family: Futura;
}
/* .contents > span > i > svg {
  margin-top: 2px;
} */
/* .contents > span > i > img {
  height: 15px;
  width: 15px;
} */

@media screen and (max-width: 600px) {
  .intro-container {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
  }
  .avatar {
    /* height: 90px; */
    /* width: 100%; */
  }

  /* .avatar-image {
    height: 120px;
    width: 120px; 
    
  } */

  /* .contents {
    margin-top: 18%;
  } */

  .contents > p {
    font-size: 1.3vh;
  }
  .contents > span {
    font-size: 1.8vh;
  }
  .contents > span > i {
    font-size: 1.5vh;
  }

  .contents > div > strong {
    font-size: 2.8vh;
  }
}

@media screen and (max-width: 480px) {
  .intro-container {
    width: 100%;
    padding-left: 16px !important;
    padding-right: 16px !important;
    text-align: center;
    margin-top: 5px;
  }

  .avatar {
    /* height: 90px; */
    width: 320px;
  }
  .avatar-back {
    width: 320px;
  }

  .avatar-image {
    height: 120px;
    width: 120px;
    /* margin-top: 70px; */
  }
  .contents > p {
    font-size: 0.9vh;
  }
  .contents > span {
    font-size: 1.6vh;
    font-style: italic;
  }
  .contents > span > i {
    font-size: 1.4vh;
  }

  .contents > div > strong {
    font-size: 2.6vh;
  }
}

@media screen and (max-width: 320px) {
  .intro-container {
    width: 100%;
  }

  .avatar {
    /* height: 90px; */
    width: 100%;
  }
  .avatar-back {
    width: 100%;
  }
}
