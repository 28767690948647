.about-container {
  width: 700px;
  text-align: center;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
}
.about-buttons {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  /* flex: 100%; */

}

.previous {
  /* width: 30%; */
  width: 90px;
  text-align: center;
  font-size: 1.5vh;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0 0.2em rgb(202, 201, 201) !important;
  color: black !important;
  
}
.previous:disabled{
  cursor: not-allowed;
}

.next {
  width: 90px;
  /* width: 30%; */
  font-size: 1.5vh;
  text-align: center;
  background-color: rgb(255, 255, 255) !important;
   box-shadow: 0 0.2em rgb(202, 201, 201) !important;
   color: black !important;
}
.next:disabled{
  cursor: not-allowed;
}

@media screen and (max-width: 760px) {
  .about-container {
    width: 100%;
    /* padding-left: 16px !important;
    padding-right: 16px !important; */
  }
  .previous {
    /* width: 28%; */
    /* width: 100%; */
  }
  
  .next {
    /* width: 28%; */
    /* width: 100%; */
  }
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .about-container {
    width: 100%;
    /* margin: 16px; */
    /* padding-left: 16px !important;
    padding-right: 16px !important; */
  }
  .previous {
    /* width: 25%; */
    /* width: 100%; */
  }
  
  .next {
    /* width: 25%; */
    /* width: 100%; */
  }
}
