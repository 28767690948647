/* @import url("https://fonts.googleapis.com/css?family=Press+Start+2P"); */
@import url("react-tooltip/dist/react-tooltip.css");

* {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

body{
  height: 100%;
  margin: 0;
  background-color: black;
}

body::-webkit-scrollbar {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  text-align: center;
  padding: 1rem;
  /* background-color: #111; */
}

.space {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 0.2px;
  width: 0.2px;
  background-color: #fff;
  border-radius: 90%;
  z-index: -1;
  box-shadow:
    43vw -10vh 0px 2px #ffffff,
    -23vw -5vh 2px 2px #fff,
    22vw -48vh 2px 0px #fff,
    1vw 28vh 2px 1px #fff,
    8vw -33vh 2px 2px #fff,
    8vw -3vh 1px 1px #fff,
    -23vw -47vh 0px 1px #fff,
    16vw -21vh 1px 2px #fff,
    -46vw -2vh 2px 0px #fff,
    25vw -43vh 2px 1px #fff,
    -6vw -13vh 1px 0px #fff,
    27vw -40vh 2px 0px #fff,
    -9vw -40vh 2px 0px #fff,
    19vw 29vh 0px 2px #fff,
    19vw -28vh 0px 2px #fff,
    -9vw -24vh 2px 2px #fff,
    -3vw -21vh 2px 2px #fff,
    48vw -37vh 1px 0px #fff,
    7vw -44vh 2px 2px #fff,
    -35vw -42vh 1px 1px #fff,
    25vw 28vh 2px 1px #fff,
    42vw -10vh 0px 0px #fff,
    -25vw 34vh 2px 0px #fff,
    -28vw -31vh 2px 2px #fff,
    27vw 12vh 1px 0px #fff,
    8vw -37vh 1px 1px #fff,
    39vw 40vh 2px 1px #fff,
    -11vw 8vh 2px 0px #fff,
    12vw 35vh 2px 2px #fff,
    -3vw 23vh 2px 2px #fff,
    -36vw -49vh 0px 1px #fff,
    28vw 9vh 0px 0px #fff,
    -34vw -32vh 1px 0px #fff,
    -50vw -9vh 0px 2px #fff,
    4vw -28vh 0px 0px #fff,
    -30vw -9vh 2px 1px #fff,
    -50vw -10vh 2px 2px #fff,
    44vw -23vh 2px 1px #fff,
    0vw 37vh 1px 2px #fff,
    28vw 38vh 2px 0px #fff,
    21vw 7vh 1px 2px #fff,
    -45vw 48vh 0px 1px #fff,
    1vw -24vh 1px 1px #fff,
    -1vw -27vh 2px 2px #fff,
    -50vw 1vh 0px 2px #fff,
    -4vw -25vh 0px 2px #fff,
    36vw 15vh 0px 1px #fff,
    -14vw -17vh 1px 2px #fff,
    -41vw 26vh 1px 2px #fff,
    -26vw -5vh 0px 1px #fff,
    -22vw 49vh 2px 2px #fff,
    29vw -23vh 2px 0px #fff,
    4vw -35vh 1px 0px #fff,
    -20vw -14vh 1px 1px #fff,
    -6vw 36vh 0px 2px #fff,
    4vw 15vh 1px 1px #fff,
    -3vw -49vh 1px 1px #fff,
    -39vw 28vh 0px 2px #fff,
    49vw -41vh 1px 2px #fff,
    -33vw 4vh 0px 2px #fff,
    -22vw 42vh 2px 1px #fff,
    -25vw -8vh 0px 2px #fff,
    -17vw -18vh 1px 2px #fff,
    8vw -38vh 2px 2px #fff,
    -20vw -12vh 2px 2px #fff,
    36vw 27vh 2px 1px #fff,
    5vw 43vh 1px 2px #fff,
    -45vw -28vh 2px 2px #fff,
    28vw 17vh 2px 2px #fff,
    22vw 24vh 1px 2px #fff,
    -39vw -26vh 2px 1px #fff,
    -44vw -24vh 0px 1px #fff,
    1vw -48vh 2px 2px #fff,
    -33vw 36vh 2px 1px #fff,
    -29vw -47vh 2px 1px #fff,
    4vw 3vh 2px 2px #fff,
    32vw 33vh 0px 2px #fff,
    -35vw 25vh 2px 2px #fff,
    8vw 5vh 2px 0px #fff,
    -50vw -4vh 2px 0px #fff,
    3vw -34vh 0px 0px #fff,
    -5vw 42vh 2px 2px #fff,
    -7vw 29vh 0px 1px #fff,
    1vw -15vh 2px 2px #fff,
    -21vw 14vh 0px 2px #fff,
    -14vw 38vh 0px 2px #fff;
  animation: zoom 15s alternate infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main > * {
  width: 100%;
  max-width: 800px;
  padding: 1rem;
}

.content-wrap {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 1rem;
  position: relative;
}

.fixed-navbar {

  position: fixed;
  text-align: center;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9); /* Match the footer background */
  z-index: 10;
}

.footer-scroll {
  position: relative;
}

.footer > p {
  color:#fdd612;
  font-size: small;
}

/* .shadow {
  animation: changeFilter 4s alternate infinite;
} */

@keyframes changeFilter {
  0% {
    filter: drop-shadow(5px -5px 7px #ffffff);
  }
  15% {
    filter: drop-shadow(5px -5px 7px #f2f2f2);
  }
  30% {
    filter: drop-shadow(5px -5px 7px #e5e5e5);
  }
  45% {
    filter: drop-shadow(5px -5px 7px #d8d8d8);
  }
  60% {
    filter: drop-shadow(5px -5px 7px #cccccc);
  }
  70% {
    filter: drop-shadow(5px -5px 7px #bfbfbf);
  }
  85% {
    filter: drop-shadow(5px -5px 7px #b3b3b3);
  }
  100% {
    filter: drop-shadow(5px -5px 7px #a6a6a6);
  }
}

@keyframes changeText {
  0% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #ffffff;
  }
  15% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #f2f2f2;
  }
  30% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #e5e5e5;
  }
  45% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #d8d8d8;
  }
  60% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #cccccc;
  }
  70% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #bfbfbf;
  }
  85% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #b3b3b3;
  }
  100% {
    color: #ffffff;
    text-shadow: 2px 2px 4px #a6a6a6;
  }
}

/* h1 {
  color: white;
  animation: changeText 4s alternate infinite;
} */

@keyframes changeBorder {
  0% {
    border-color: #ffffff;
    box-shadow: 0 0 5px #ffffff;
  }
  15% {
    border-color: #f2f2f2;
    box-shadow: 0 0 5px #f2f2f2;
  }
  30% {
    border-color: #e5e5e5;
    box-shadow: 0 0 5px #e5e5e5;
  }
  45% {
    border-color: #d8d8d8;
    box-shadow: 0 0 5px #d8d8d8;
  }
  60% {
    border-color: #cccccc;
    box-shadow: 0 0 5px #cccccc;
  }
  70% {
    border-color: #bfbfbf;
    box-shadow: 0 0 5px #bfbfbf;
  }
  85% {
    border-color: #b3b3b3;
    box-shadow: 0 0 5px #b3b3b3;
  }
  100% {
    border-color: #a6a6a6;
    box-shadow: 0 0 5px #a6a6a6;
  }
}
@media screen and (max-width: 900px) {
  .main > * {
    max-width: 100%;
    padding: 1rem;
  }
}

@media screen and (max-width: 760px) {
  body {
    overflow-x: hidden;
  }

  .main {
    width: 100%;
    /* padding-left: 32px !important;
    padding-right: 32px !important; */
  }
  .main > * {
    width: 100%;
    /* margin-top: 3%; */
  }

  .footer {
    /* margin-top: 7%; */
  }
  .footer > footer > p {
    font-size: small;
  }
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  body {
    overflow-x: hidden;
  }
  .main {
    /* margin-top: 5%; */
    width: 100%;
    /* padding-left: 16px !important;
    padding-right: 16px !important; */
  }
  .main > * {
    width: 100%;
  }
  .footer {
    /* margin-top: 4%; */
  }
  .fixed-navbar {

    padding-left: 16px;
    padding-right: 16px;
  }
  .footer > footer > p {
    font-size: x-small;
  }
}
