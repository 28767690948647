.logo-container {
  display: flex;
  justify-content: center;
}
.logo-button{
  background-color: black;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.logo {
  height: 150px;
  width: 150px;
  filter: contrast(200%);
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .logo {
    height: 150px;
    width: 150px;
  }
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .logo {
    /* margin-top: 10px; */
    height: 150px;
    width: 150px;
  }
}
