.project-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
}

.project-item-container  > h1{
  font-size: 3vh;
  background-color: black;
  display: inline-block;
  color: #fdd612;
}

.project-desc{
  background-color: black;
  width: 650px;
}

.project-video {
  display: flex;
  justify-content: center;
  width: 100%;

}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

}

.live{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.git{
  display: flex;
  align-items: center;
}

.link-button {
  display: inline-block;
  padding: 15px 15px 0px 15px;
  cursor: pointer;
  text-decoration: none;
}

.link-button:hover {
  cursor: pointer;
  box-shadow: none;
  position: relative;
  top: 0.3em;
}

.tools-used {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tools-used > * {
  margin-left: 10px;
}

@media screen and (max-width: 760px) {
  .project-item-container{
    width: 100%;
    /* padding-left: 32px !important;
    padding-right: 32px !important; */
  }
  .project-desc{
  
    width: 100%;
  }
	.project-item-container > h1 {
		font-size: 2.8vh;
	}
 

}

@media screen and (max-width: 480px) {
  .project-item-container{
    width: 100%;
  }
  .project-desc{
  
    width: 100%;
  }
	.project-item-container > h1 {
		font-size: 2.5vh;
	}
 
}


