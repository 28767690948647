.cheer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.player {
  display: flex;
  flex-direction: row;
}
.player > * {
  margin: 10px;
  cursor: pointer;
  background-color: rgb(27, 25, 25);
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px;
}

.player > *:hover {
  border: 2px solid white;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
}
.cheer-up {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  outline: none;

}

.cheer-up > div > img {
  cursor: pointer;
  margin-top: 0;
  width: 60px;
  height: 60px;
}

.cheer-up > span {
  cursor: pointer;
  margin-top: 30px;
  /* height: 25px; */
  font-size: 1.5vh;
  text-decoration: underline;
  background-color: rgb(0, 0, 0);
  /* animation: changeText 8s linear infinite; */
}

.music{
  animation: rotate 2s linear infinite;
}

.music-div{
  animation: changeBorder 8s linear infinite;
  border-radius: 50%;
}
