.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
.modal-content {
 
}

.modal-content > p {
}

.choices{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.proceed{
  font-size: 13px;
}
.cancel{
  font-size: 13px;
}

@media screen and (max-width: 760px) {
  .popup-container {
    width: 70%;
  }

}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .popup-container {
    width: 70%;
  }

  .proceed{
    font-size: 11px;
  }
  .cancel{
    font-size: 11px;
  }
}
