.blog-item-container {
  background-color: rgb(27, 25, 25);
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0);
  padding: 8px;
}
.blog-item-container:hover {
  border: 2px solid white;
  background-color: black;
}

.blog-item-container > * {
  margin: 10px;
  text-align: left;
  color: rgb(255, 255, 255);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  max-width: 100%;
}

.imgDiv {
  height: 65px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  background-size: auto;
}
.desc > * {
  margin-left: 20px;

  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  line-height: 0%;
}

.desc > p {
  font-size: smaller;
  color: white;
}

.desc > time {
  font-size: x-small;
  color: rgb(170, 170, 170);
}

.blog-item-container > .imgDiv > img {
  border-radius: 50%;
  object-fit: cover;
}

.blog-item-container > p {
  margin-bottom: 5%;
  font-size: 17px;
}

.blog-item-container > p > a {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #fdd612 !important;
}
.blog-item-container > p > a:hover {
  /* color: #39ff14; */
  cursor: pointer;
}
.blog-item-container > h2 {
  font-size: 25px;
  color: #fdd612 !important;
}

.tag {
  background-color: white;
  /* background-color: #fdd612; */
  float: left;
  width: fit-content;
  text-align: center;
  border-radius: 3px;
}

.tag > span {
  padding: 4px;
  text-align: center;
  color: rgb(27, 25, 25);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
