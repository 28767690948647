.project-item-card {
  display: flex;
  flex-direction: column;
  justify-content: top;
  background-color: rgb(27, 25, 25);

  /* height: 380px; */
  width: 550px;
  margin-bottom: 35px;
  cursor: pointer;
  border-radius: 20px;
}

.project-item-card:hover {
  position: relative;
  top: 0.3em;
}

.video-player {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1 0px;
  transition: transform 500ms;
  background-color: black;
  width: 550px;

  /* width: 500px; */
}

.video-player:hover {
  /* z-index: 1000; */
  /* transform: scale(1.1); */
  background-color: black;
  cursor: pointer;
  /* transform: translateY(-5px); */
  /* box-shadow: 0px 0px 30px 30px rgba(255, 255, 255, 0.25); */
}

.card-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.card-contents:hover {
  /* border-radius: 20px; */
  /* border: 2px solid white; */
  /* background-color: black; */
  /* margin: 0px; */
  /* padding-left: 0px;
  padding-right: 0px; */
}

.card-contents > * {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.card-contents > h2 {
  font-size: 18px;
  /* animation: changeText 8s linear infinite; */
  color: #fdd612;
}
.card-contents > h2:hover {
  font-size: large;
  cursor: pointer;
  /* color: #39ff14 !important; */
}

.click-link {
  margin-bottom: -10px;
  margin-left: 5px;
}
.click-link > g {
  animation: changeFill 8s linear infinite;
}

@keyframes changeFill {
  0% {
    fill: #ffffff;
  }

  15% {
    fill: #f2f2f2;
  }

  30% {
    fill: #e5e5e5;
  }

  45% {
    fill: #d8d8d8;
  }

  60% {
    fill: #cccccc;
  }

  70% {
    fill: #bfbfbf;
  }

  85% {
    fill: #b3b3b3;
  }

  100% {
    fill: #a6a6a6;
  }
}

@media screen and (max-width: 760px) {
  .project-item-card {
    /* width: 100%; */
  }
  .video-player {
    /* width: 100%; */
  }
}

@media screen and (max-width: 510px) {
  .project-item-card {
    width: 100%;
  }
  .video-player {
    width: 100%;
  }
}
