.projects-container {
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.page-title > h2{
  font-size: 3vh;
  display: inline-block;
  background-color: black;
  color: #fdd612;
}

.projects{
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 550px;
  gap: 50px;
  

}


@media screen and (max-width: 760px) {
  .page-title > h2{
    font-size: 2.8vh;

  }

}

@media screen and (max-width: 560px) {
  .projects-container {
    width: 100%;
    padding-left: 16px;
		padding-right: 16px;
  }
  .page-title > h2{
    font-size: 2.5vh;
   
  }
  .projects {
    width: 100%;

  }
}
